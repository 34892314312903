<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation key="createForm" @submit="submit" :form="$refs.createForm" :disabled="invalid">
          <n-input :fields="fields" v-model="data" :initValue="initData">
          </n-input>

          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option ref="btnSubmit" @submit="submit" @save="save" :loading="loading"
                :resource="resource" :route="route" :is-previewable="true" :hide-update="true"
                v-if="$can('create', resource)"></n-button-save-option>

              <n-button-loading type="button" v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary"
                :loading="loading" class="mt-1" @submit="back">
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
  import { BRow, BCol, BCard } from "bootstrap-vue";
  import Repository from "@/repositories/RepositoryFactory";
  import Ripple from "vue-ripple-directive";
  import NButtonLoading from "@/components/NButtonLoading";
  import NFormConfirmation from "@/components/NFormConfirmation";
  import NInput from "@/components/NInput";
  import FormInput from "./formInput";
  import NButtonSaveOption from "@/components/NButtonSaveOption";
  import { isObjectNotEmpty } from "@core/utils/utils"
  // import {   textColor } from "@/libs/helper";
  const VehicleRepository = Repository.get("vehicle");

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      NButtonLoading,
      NFormConfirmation,
      NInput,
      NButtonSaveOption,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        data: {
          vehicleBrandId: null,
          model: null,
          vehicleColorId: null,
          year: null,
          vinNumber: null,
          engineNumber: null,
          fuelTypeId: null,
          variant: null,
          engineCapacity: null,
          transmission: null,
          seatNumber: null,
          arrivalDate: null,
          note: "",
          documents: [],
        },
        initData: {},
        loading: false,
      };
    },
    mounted() {
      if (this.$route.query.id) {
        this.show();
      }
    },
    methods: {
      show() {

        VehicleRepository.show(this.$route.query.id).then((response) => {
          let data = response?.data?.data;
          delete data.id;

          if (data) {
            this.initData = {
              ...data,
              documents: [],
            };
          }
        });
      },
      submit(type) {

        const checkObject = isObjectNotEmpty(this.data);
        if (!checkObject) {

          this.$bvModal
            .msgBoxConfirm(this.$t("alert['You have to input any field to save']"), {
              title: this.$t("alert.somethingWentWrong"),
              size: "sm",
              buttonSize: "sm",
              okVariant: this.okVariant,
              headerClass: 'border-danger',
              hideCancel: false,
              hideHeaderClose: false,
              titleClass: 'text-danger',
              centered: true,
            })
            .then((value) => {
              if (value) {
                // this.$emit("submit");
              }
            });
          return;
        }


        this.$refs.createForm.validate().then((success) => {
          console.log({ success });

          if (success) {
            this.$refs.btnSubmit.confirm(type);
          }
        });
      },
      save(type) {
        this.loading = true;


        VehicleRepository.create(this.data)
          .then((response) => {

            this.$refs.btnSubmit.afterSave(type, response.data.data.id);
          })
          .catch((error) => {
            if (error.response?.status == 422) {
              console.log({ error });
              this.$refs.createForm.setErrors(error.response?.data?.message);
            }
          })
          .then(() => {
            this.loading = false;
          });
      },
      back() {
        this.$router.push({
          name: `list-${this.route}`,
        });
      },
    },
    setup() {
      const fields = FormInput;
      const resource = "vehicle";
      const route = "vehicle";
      return { fields, resource, route };
    },
  };
</script>